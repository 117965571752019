<template>
  <div id="home">
    <img v-if="isDarkMode" class="logo" src="@/assets/img/logo-dark.svg" alt="CFS logo" />
    <img v-else class="logo" src="@/assets/img/logo-light.svg" alt="CFS logo" />
    <p>{{ $t('home.indication') }}</p>
  </div>
</template>
<script>
export default {
  components: {},
  computed: {
    isDarkMode() {
      return this.$store.getters.DARK;
    }
  }
};
</script>

<style lang="sass" scoped>
#home
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
  height: calc(100% - 85px)
  .logo
    width: 550px
    height: 303px
    @media screen and ( min-width: $bp-xxl )
      width: 448px
  p
    font-weight: 600
    font-size: $f-xxl
    color: $dark
    text-align: center
</style>
<style lang="sass" scoped>
.dark
  #home
    p
      color: $main-background
</style>
